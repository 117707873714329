import React from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import Header from "../components/Header";
import ShortImageBlock from "../components/ShortImageBlock";

import images from "../api/MoreInfoImages.json";
import colors from "../api/Colors.json";

import "./MoreInfoScreen.css";

export default class MoreInfoScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
      category: this.props.match.params.category,
      data: [],
    };
  }

  componentDidMount() {
    let imgs = [];

    for (let i = 0; i < images.length; i++) {
      if (images[i].mainId === this.state.id) {
        imgs.push(images[i]);
      }
    }

    this.setState({
      data: imgs.map((image, key) => {
        return (
          <ShortImageBlock
            id={image.id}
            path={image.path}
            description={image.description}
            backgroundColor={colors[key].color}
          />
        );
      }),
    });
  }

  render() {
    return (
      <ReactCSSTransitionGroup
        transitionName="AppearElement"
        transitionAppear={true}
        transitionAppearTimeout={1000}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div className="MainContainer">
          <Header />
          <div className="CategoryHeader">
            {this.state.category}
          </div>
          {this.state.data}
        </div>
      </ReactCSSTransitionGroup>
    );
  }
}
