import React from "react";
import "./ShortImageBlock.css";

export default class ShortImageBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      path: this.props.path,
      description: this.props.description,
      backgroundColor: this.props.backgroundColor,
    };
  }

  render() {
    let path = this.state.path.split('/');

    return (
      <div className="ImageBlockContainer">
        <div className="ImagePanelStyle">
          <img
            src={require(`../assets/images/subimages/${path[4]}`)}
            className="ImgStyle"
            alt=""
          />
        </div>
        <div className="DescBlockColor" style={{ backgroundColor: this.state.backgroundColor }}>
          <div className="DescStyle">
              {this.state.description}
          </div>
        </div>
      </div>
    );
  }
}
