import React from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import Header from "../components/Header";
import ImageBlock from "../components/ImageBlock";

import "./MainScreen.css";

export default class MainScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      colors: this.props.colors,
    };
  }

  componentDidMount() {
    this.setState({
      data: this.props.data.map((image, key) => {
        return (
          <ImageBlock
            id={image.id}
            path={image.path}
            name={image.name}
            description={image.description}
            backgroundColor={this.state.colors[key].color}
          />
        );
      }),
    });
  }

  render() {
    return (
      <ReactCSSTransitionGroup
        transitionName="AppearElement"
        transitionAppear={true}
        transitionAppearTimeout={1000}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div className="MainContainer">
          <Header />
          {this.state.data}
        </div>
      </ReactCSSTransitionGroup>
    );
  }
}
