import React from "react";
import "./Header.css";

export default class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      phone: "",
      email: "",
    };
  }

  async componentDidMount() {
    await fetch("/AppInfo.json").then((res) => {
      res.json().then((json) => {
        this.setState({
          name: json.name != undefined ? json.name : "PRofi Floor",
          phone: json.phone != undefined ? json.phone : "+380 (68) 514 37 11",
          email: json.email != undefined ? json.email : "profi.flor.info@gmail.com",
        });
      });
    });
  }

  render() {
    if(this.state.name === '' && this.state.phone === '' && this.state.email === ''){
      this.setState({
        name: "PRofi Floor",
        phone: "+380 (68) 514 37 11",
        email: "profi.flor.info@gmail.com",
      });
    }

    return (
      <div className="HeaderStyle">
        <div className="LogoStyle">{this.state.name}</div>
        <div className="ContactsBlock">
          <div className="PhoneBlockStyle">
            <div className="ImgBlock">
              <img
                src={require("../assets/icons/phone.png")}
                className="ImgStyle"
                alt=""
              />
            </div>
            <div className="NumStyle">{this.state.phone}</div>
          </div>
          <div className="EmailBlockStyle">
            <div className="ImgBlock">
              <img
                src={require("../assets/icons/mail.png")}
                className="ImgStyle"
                alt=""
              />
            </div>
            <div className="MailStyle">{this.state.email}</div>
          </div>
        </div>
      </div>
    );
  }
}
