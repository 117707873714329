/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { NavLink } from "react-router-dom";
import "./ImageBlock.css";

export default class ImageBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.id,
      path: this.props.path,
      name: this.props.name,
      description: this.props.description,
      backgroundColor: this.props.backgroundColor,
    };
  }

  render() {
    let path = this.state.path.split('/');

    return (
      <div className="ImageBlockContainer">
        <NavLink to={`/more_info/${this.state.id}/${this.state.name}`}>
          <div className="ImageBlockStyle">
            <img
              src={require(`../assets/images/${path[3]}`)}
              className="ImageStyle"
              alt=""
            />
          </div>
        </NavLink>
        <div className="TextBlockColor" style={{ backgroundColor: this.state.backgroundColor }}>
          <div className="TextBlock">
            <div className="NameStyle">{this.state.name}</div>
            <div className="DescriptionStyle">{this.state.description}</div>
            <div className="NavigationBlock">
              <NavLink to={`/more_info/${this.state.id}/${this.state.name}`} className="NavigationButton"
              >
                <text className="TextStyle">Подробнее →</text>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
