import React from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";

import images from './api/MainImagesInfo.json';
import colors from './api/Colors.json';

import MainScreen from './views/MainScreen';
import MoreInfoScreen from './views/MoreInfoScreen';

function App() {
  document.title = "PRofi Floor";
  return (
    <Router>
      <Switch>
        <Route exact path='/' component={ () => <MainScreen data={images} colors={colors}/> }/>
        <Route path='/more_info/:id/:category' render={(props) => <MoreInfoScreen {...props} />} />
      </Switch>
  </Router>
  );
}

export default App;
